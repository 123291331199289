@import '../dashboard-vars.modlue.css';

.recentEventsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 250px;
    min-width: 250px;
    padding: 0.1rem;
    border-radius: 0 36px 36px 0;
    .action {
        padding: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &.all {
            padding: 0.7rem;
            justify-content: flex-end;
            padding-right: 1.5rem;
        }
    }
    .button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    * {
        color: var(--gray-1);
    }
    background-color: var(--green-background-color);
    .eventsContainer {
        display: initial;
        min-width: 50px;
        flex: 1;
    }
    hr {
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        border: 0.1rem solid var(--gray-1);
    }
}

// Mobile version
@media (max-width: 900px) {
    .recentEventsContainer {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        flex-direction: row;
        justify-content: space-between;
        .eventsContainer {
            display: none;
        }
    }
}
