.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2rem;

    section {
        flex: 1 1;
        margin: 0.5rem;
    }
}

.contactsContainer {
    min-width: 500px;
    max-width: 500px;
}

.notificationsContainer {
    min-width: 400px;
    height: fit-content;
}

.calendarEventsContainer {
    min-width: 550px;
    max-width: 500px;
}

.recentReportsSection {
    min-width: 450px;
    height: fit-content;
}
.welcomeContainer {
    min-width: 350px;
}

@media (max-width: 900px) {
    .row {
        padding: 0rem;
    }
    .calendarEventsContainer {
        min-width: 100%;
    }
    .notificationsContainer {
        min-width: 100%;
    }
    .welcomeContainer {
        min-width: 100%;
    }
    .contactsContainer {
        min-width: 100%;
    }
    .recentReportsSection {
        min-width: 100%;
    }
}
