.mainContainer {
    width: 100%;
    height: 100%;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 20px;
}

.searchContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.doclink {
    text-decoration: underline;
    color: var(--doclink-color-green-1);
}