@import '../../colors.css';

.container {
    border-radius: 8px;
    border-width: 1px;
    margin-bottom: 16px;
}

.title {
    background: var(--grey-200);
    padding: 4px 12px;
    border-radius: 8px;
    margin: 5px;
}

.hideLegend {
    display: none;
}

.showLegend {
    display: block;
}
