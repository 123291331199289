.notificationsContainer {
    margin: 0 0.2rem;
    .notifications {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        padding: 0.5rem;
    }
}
