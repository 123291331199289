@import url(../../colors.css);

.title {
    width: 100%;
}

.dateRange {
    display: flex;
    margin: 0.5rem;
    gap: 5px;
    align-items: center;
}

.selectDistrict {
    margin-top: 10px;
}

.error {
    color: var(--error-main)
}