.dateInfo {
    display: flex;
    flex-direction: row;
    > span {
        margin: 0 0.5rem 0.5rem 0.5rem;
    }
    span:first-of-type {
        margin-left: 0;
    }
}
.description {
    margin-top: 1.5rem;
}
