.card {
    min-width: 250px;
    height: 155px;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    .cardContent {
        padding: 0.7rem;
        padding-bottom: 0;
        padding-left: 1.5rem;
        flex: 1;
        .content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }
    }
    .actions {
        margin-top: 0;
        padding: 0.7rem;
        padding-right: 1.5rem;
        display: flex;
        flex-direction: row-reverse;
    }
}
