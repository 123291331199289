.analyticsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    .apiRef {
        margin-bottom: 2rem;
    }
    .content {
        width: 100%;
        height: 100%;
        margin-top: 2rem;

        .resultsContainer {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
    }
}
.GaConatiner {
    padding: 0;
}

@media (max-width: 900px) {
    .GaConatiner {
        margin-left: -1.5rem;
    }
}
