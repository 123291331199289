.fileContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 42px;
    align-items: center;
}

.iconStyle {
    max-width: 20px;
    min-width: 20px !important;
}

.doclink {
    text-decoration: underline;
    color: var(--doclink-color-green-1);
}

.foldertype {
    cursor: pointer;
}
