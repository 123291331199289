.displaySection {
    display: block;
}

.hideSection {
    display: none;
}

.listContent {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.listItem {
    display: flex;
    justify-content: space-between;
}
