.bannerLogo {
    height: 48px;
}
.headerContainer {
    grid-column: 1/3;
    background-color: rgb(246, 245, 250) !important;
}
.banner {
    position: absolute;
    left: 0;
    top: 0;
}
.capitalized {
    text-transform: uppercase;
}

@media screen and (max-width: 800px) {
    .bannerLogo {
        margin-bottom: 24px;
    }
    .appVersion {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
}
