.eventSectionContainer {
    min-width: 360px;
    .container {
        display: flex;
        flex-direction: row;
        .card {
            padding: 0;
            margin: 0;
        }
        .cardContainer {
            display: flex;
            margin: 0;
            padding: 0;
        }
    }
}

@media (max-width: 900px) {
    .eventSectionContainer {
        min-width: 100%;
        .container {
            flex-direction: column;
            .cardContainer {
                flex-direction: column-reverse;
            }
        }
    }
}
