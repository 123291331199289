.contacts {
    .card {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}
.actions {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
}

@media (max-width: 600px) {
    .contacts {
        padding: 0;
        .card {
            > div {
                padding: 0;
                margin: 0;
            }
        }
    }
    .actions {
        nav {
            width: 100%;
        }
        ul {
            display: flex;
            justify-content: space-between;
            li {
                display: none;
            }
            li:first-of-type {
                display: initial;
            }
            li:last-of-type {
                display: initial;
            }
        }
    }
}
