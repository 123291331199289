.reportsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 100%;
}

.errorBoundaryContainer {
    border-radius: 8px;
    transform: none;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
}

.headerContainer {
    display: flex;
    gap: 2px;
    justify-content: flex-end;
    margin-bottom: 16px;
    padding: 0 16px;
    width: 100%;
}

.uploadContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.uploadInnerContainer {
    width: 90%;
    justify-content: 'center';
}