.cardcontent {
    justify-content: center;
}

.assignmentlist {
    width: 100%;
    max-height: 500px;
    min-height: 500px;
    overflow: auto;
    background-color: 'background.paper';
}
