.mainContainer {
    width: 100%;
    height: 100%;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 20px;
}
.searchBox {
    margin: auto;
    margin-bottom: 16px;
}
.supportContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    padding: 1px;
    width: 100%;
}
