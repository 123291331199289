.modalContent {
    min-width: 400px;
}
.actions {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    button {
        margin: 8px 16px;
    }
}

.dialogTitle {
    display: flex;
    justify-content: space-between;
}