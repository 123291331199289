.pb {
    margin-left: 24px;
    overflow: auto;
    .pbCompany {
        padding: 1rem;
        box-shadow: 0px 4px 4px 0px var(--orange-100) inset;
        display: flex;
        gap: 1rem;
        > legend {
            display: none;
        }
    }
    .pbList {
        .pbActions {
            justify-content: end;
        }
        .pbTable {
            padding-left: 0;
            padding-right: 0;

            > table {
                margin: 0;
            }
        }
    }
    .pbUpdate {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;

        .fitParent {
            max-width: unset;
            min-width: 100%;
            &.inputText {
                input {
                    min-width: 100%;
                    height: 46px;
                }
            }
            .customWidth {
                min-width: 100%;
                width: 100%;
                > div {
                    width: 100%;
                }
            }
        }
    }

    .pbUpdateActions {
        flex-wrap: wrap;
        justify-content: end;
    }
}
