.small {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    span {
        font-size: 0.5rem;
    }
}

.medium {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
}

.large {
    min-width: 62px;
    max-width: 62px;
    min-height: 62px;
    max-height: 62px;
}
