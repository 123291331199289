.loginPage {
    height: 100vh;
    min-width: 300px;
    .loginImage {
        width: 100%;
        height: 90vh;
        background-image: url('../../Images/Login-amico.svg'), url('../../Images/Logo_Small_Transparent.png');
        background-size: 400px, 200px;
        background-repeat: no-repeat;
        background-position: center, 2rem 2rem;
    }
    .loginForm {
        width: calc(80vw);
        box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
        align-items: center;
        justify-content: center;
        > div {
            min-width: 300px;
            max-width: 500px;
            width: calc(30vw);
        }
    }
}

@media (max-width: 1200px) {
    .loginPage {
        .loginForm {
            > div {
                width: calc(35vw);
            }
        }
    }
}

@media (max-width: 1100px) {
    .loginPage {
        .loginForm {
            > div {
                width: calc(40vw);
            }
        }
    }
}

@media (max-width: 900px) {
    .loginPage {
        .loginImage {
            display: none;
        }
        .loginForm {
            width: 100%;
            background-image: url('../../Images/Logo_Small_Transparent.png');
            background-size: 200px;
            background-repeat: no-repeat;
            background-position: 2rem 2rem;
            > div {
                width: calc(60vw);
            }
        }
    }
}
