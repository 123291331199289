@import '../../colors.css';

:root {
    --region-color: #9090f7;
    --region-background-color: #b6b6d1;
    --company-color: #2222ff;
    --company-background-color: #cfcfff;
    --ranch-color: #020276;
    --ranch-background-color: #ceceeb;
    --library-color: #ffdb24;
    --library-background-color: #fffada;
    --report-color: #057ea9;
    --report-background-color: #d5e9f2;
    --support-color: #715094;
    --support-background-color: #e8e0e8;
    --announcement-color: var(--sun-yellow);
    --announcement-background-color: var(--sun-yellow-transparent);

    --green-background-color: #4a773cc4;
    --box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    --gray-1: #f5f6f8;
    --gray-2: #9ca3af;
    --gray-3: #00000067;
    --white: #ffffff;
    --white-1: #fcfaf8;

    --blue: #0076a5;
}
