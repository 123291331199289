@import '../dashboard-vars.modlue.css';

.notificationContainer {
    margin: 0.3rem;
    &.Region,
    &.District {
        border-bottom: 2px solid var(--region-color);
    }
    &.Company {
        border-bottom: 2px solid var(--company-color);
    }
    &.Ranch {
        border-bottom: 2px solid var(--ranch-color);
    }
    &.Library {
        border-bottom: 2px solid var(--library-color);
    }
    &.Report,
    &.GrowerSettlement,
    &.QAInspection {
        border-bottom: 2px solid var(--report-color);
    }
    &.Support {
        border-bottom: 2px solid var(--support-color);
    }
    &.Announcement {
        border-bottom: 2px solid var(--announcement-color);
    }
    .notificationType {
        padding: 0.2rem 0.5rem;
        text-align: center;
        &.Region,
        &.District {
            background: var(--region-background-color);
        }
        &.Company {
            background: var(--company-background-color);
        }
        &.Ranch {
            background: var(--ranch-background-color);
        }
        &.Library {
            background: var(--library-background-color);
        }
        &.Report,
        &.GrowerSettlement,
        &.QAInspection {
            background: var(--report-background-color);
        }
        &.Support {
            background: var(--support-background-color);
        }
        &.Announcement {
            background: var(--announcement-background-color);
        }
    }
    > .notification {
        border-radius: 0;
        .notificationContent {
            padding: 1rem;
            min-height: 145px;
            max-height: 145px;
            min-width: 140px;
            max-width: 140px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .smallText {
        font-size: 0.7rem;
    }
    .quickViewContainer {
        border-radius: 36px;
        box-shadow: var(--box-shadow);
        padding: 0 0.5rem;
        .quickView {
            font-size: 0.6rem;
        }
    }
}

.overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 120px; // ellipsis will only work when element's width is in px
}
