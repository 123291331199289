.CalendarContainer {
    min-height: 700px;
    max-width: 100%;
    margin: 0px auto;
    min-width: 80%;

    .actions {
        display: flex;
        flex-direction: row-reverse;
        padding: 1rem;
        align-items: center;
        margin-bottom: 1rem;
    }
}

.calendarContent {
    max-width: 100%;
}
