@import '../dashboard-vars.modlue.css';
@import '../../../colors.css';

.recentReports {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 36px;
    .title {
        width: 100%;
        border-bottom: 1px solid VR(--grey-2);
    }
    .card {
        width: 100%;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        .content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
