.container {
    width: 100%;
    .content {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;

        .title {
            width: 100%;
        }
    }
    .actions {
        display: flex;
        flex-direction: row-reverse;
        .create {
            margin-left: 2rem;
        }
    }
    .dateRange {
        display: flex;
        span {
            padding: 0.5rem;
        }
    }
}
