.cardcontent {
    justify-content: center;
}

.cardsearch {
    width: 100%;
    display: flex;
    justify-content: center;

    .searchbox {
        width: 80%;
    }
}

.userslist {
    width: 100%;
    max-height: 450px;
    min-height: 450px;
    overflow: auto;
    background-color: 'background.paper';

    .secondaryLabel {
        margin: 0px;
    }
}

.usercardfooter {
    display: flex;
    min-height: 50px;
    align-items: center;
    justify-content: right;

}