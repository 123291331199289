.reportContainer {
    margin: 0 0.2rem;
    max-width: 300px;
    min-width: 200px;
    .report {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        padding: 0.5rem;
    }
}
