@import '../../colors.css';

.container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 40px;
    margin: 20px auto;
}

.card {
    min-width: 200;
    max-width: 500;
    min-height: 200;
}

.box {
    width: 300px;
    height: 200px;
    display: flex;
    align-items: center;
}

.div {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

.personaList {
    width: 100%;
    max-width: 360px;
    background-color: var(--grey-0);
}

.flexBoxCenter {
    display: flex;
    justify-content: center;
}

.flexColumnCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.failedApiBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .insideBox {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        max-width: 600px;
    }

    .image {
        width: 400px
    }
}
