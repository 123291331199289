.subject{
    font-weight: bolder;
    font-family: Rubik;
}
.query{
  font-family: Rubik;
}
.divider{
    width: 100%;
    margin: 24px 0
}
.responseTextArea {
  width: 100%;
  margin-top: 30px;
}

.responseTextArea textarea {
  border: none;
}
.responseStatusContainer {
  display: flex;
  justify-content: space-between;
}