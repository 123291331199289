.imageComponent {
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: center;

    .imageContainer {
        &.smaller {
            max-width: 16px;
            min-width: 16px;
        }
        &.small {
            max-width: 24px;
            min-width: 24px;
        }

        &.medium {
            max-width: 48px;
            min-width: 48px;
        }

        &.large {
            max-width: 62px;
            min-width: 62px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .caption {
        color: var(--blue);
    }
}
