.contactItem {
    display: flex;
    .item {
        display: flex;
        flex-wrap: nowrap;
        .avatarSection {
            flex: 1;
        }
        .infoContainer {
            flex: 3;
            width: 100%;
            > div {
                display: flex;
                flex-wrap: wrap;
                margin: 0.5rem 0;
                padding: 0;
            }
        }
    }
}

@media (max-width: 600px) {
    .contactItem {
        .item {
            .avatarContainer {
                max-width: 24px;
                margin-right: 0.3rem;
            }
            .phoneContainer {
                display: flex;
                flex-wrap: wrap;
                font-size: 0.8rem;
                margin: 0.5rem 0;
                padding: 0;
                svg {
                    max-width: 12px;
                }
            }
            .emailContainer {
                display: flex;
                flex-wrap: wrap;
                font-size: 0.8rem;
                margin: 0.5rem 0;
                padding: 0;
                svg {
                    max-width: 12px;
                }
            }
        }
    }
}
