.logoContainer {
    height: 40px !important;
}
.footerImage {
    height: 100% !important;
}
.sectionFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    max-height: 50px;
}
