.reportsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
}

.tableauIcon {
    width: 16px;
    margin: 4px 0 0 12px;
}
