@import url('../../colors.css');

.btnTitle {
    font-weight: bold;
}
.reportsContainer {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 5px;
    padding: 2px;
    margin: 3px;
    align-items: center;
}

.reportsGrid {
    margin-top: 1rem;
}

.assignmentError {
    color: var(--error-main);
    width: 100%;
    margin-left: 16px;
}

.divider {
    width: 100%;
    margin: 24px 0;
}

.contentHeading {
    font-weight: bold;
}
.contentBottom {
    font-weight: bold;
    margin-bottom: 32px;
}

.btn {
    display: flex;
    height: 36px;
    gap: 5px;
}

.assignments {
    gap: 16px;
    margin: auto;
    justify-content: space-evenly;
}
