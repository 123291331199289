@import '../../colors.css';

.tooltip div {
    background-color: var(--white-3) !important;
    color: var(--black-transparant-1);
    font-size: 12px;
    border: 1px solid var(--light-blue-1);
    box-shadow: 2px 1px 5px var(--grey-600);
    border-radius: 10px;
    padding: 10px;
}
