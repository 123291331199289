.filterContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding: 0.5rem;
    border-radius: 4px;
}

.filterWrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.filterOptionsContainer {
    display: flex;
    justify-content: space-between;
}

.filterButtonsContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}
