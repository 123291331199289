@import url('../../colors.css');

.linkContainer {
    text-decoration: none;
    cursor: pointer;
    color: var(--primary-main)
}

.cardContainer {
    min-height: 150px;
    max-height: 150px;
    max-width: 200px;
    min-width: 200px;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}