.container {
    display: flex;
    padding: 10px 0;
}

.editTextField {
    margin: 0 1rem 2rem 1rem;
    width: 240px;
}

.personalInfoContainer {
    margin: 24px;
}

.checkbox {
    margin: -1rem 0;
    width: 280px;
}

.loader {
    height: 150px;
    width: 82vw;
}
