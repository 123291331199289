.col {
    width: 360px;
}

.button {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 600px) {
    .col {
        width: 200px;
    }
}
