.imageBox {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
}

.titleBox {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.bottomBox {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  p {
    font-size: small;
    font-family: 'Rubik', sans-serif;
  }
}