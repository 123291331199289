.resultTime {
    margin: 0 10px 4px 0px;
    font-family: 'Rubik', sans-serif;
    font-size: 13px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.toolbarContainer {
    display: flex;
    justify-content: flex-end;
}

.footerContainer {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 55px;
    padding-top: 36px;
}

.allowExport {
    height: 100px;
    padding-top: 80px;
}

.loadMoreButton {
    font-size: 12px !important;
    margin-right: 8px;
}
