.infoContainer {
    margin-top: 1rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: row;
    min-width: 300px;
    > :last-child {
        margin-left: 2rem;
    }
}

@media (max-width: 900px) {
    .infoContainer {
        min-width: 150px;
        flex-direction: column;
        > :first-child {
            margin-bottom: 2rem;
        }
    }
}
