@import '@driscollsinc/users-management-module-ui/dist/index.css';
@import '@driscollsinc/style-guide-web2.0/dist/index.css';
@import '@driscollsinc/pti-module-ui/dist/index.css';

:root {
    /* Colors for MUI components */
    --grey-0: #ffffff;
    --grey-100: #f9fafb;
    --grey-200: #f4f6f8;
    --grey-300: #dfe3e8;
    --grey-400: #c4cdd5;
    --grey-500: #919eab;
    --grey-600: #637381;
    --grey-700: #454f5b;
    --grey-800: #212b36;
    --grey-900: #161c24;

    --secondary-lighter: #d6e4ff;
    --secondary-light: #84a9ff;
    --secondary-main: #3366ff;
    --secondary-main: #809f76;
    --secondary-dark: #1939b7;
    --secondary-darker: #091a7a;
    --secondary-contrastText: #fff;

    --info-lighter: #d0f2ff;
    --info-light: #74caff;
    --info-main: #1890ff;
    --info-dark: #0c53b7;
    --info-darker: #04297a;
    --info-contrastText: #fff;

    --success-lighter: #e9fcd4;
    --success-light: #aaf27f;
    --success-main: #54d62c;
    --success-dark: #229a16;
    --success-darker: #08660d;
    --success-contrastText: var(--grey-800);

    --warning-lighter: #fff7cd;
    --warning-light: #ffe16a;
    --warning-main: #ffc107;
    --warning-dark: #b78103;
    --warning-darker: #7a4f01;
    --warning-contrastText: var(--grey-800);

    --error-lighter: #ffe7d9;
    --error-light: #ffa48d;
    --error-main: #ff4842;
    --error-dark: #b72136;
    --error-darker: #7a0c2e;
    --error-contrastText: #fff;

    /* Driscoll's colors */

    --raspberry-pink: #c63663;
    --blackberry-purple: #6f5091;
    --blueberry-blue: #0076a5;
    --strawberry-red: #da291c;
    --sun-yellow: #fdda24;
    --icon-yellow: #fec800;
    --sun-yellow-transparent: #fdd92467;

    --grey-1: #717679;
    --grey-3: #9ca3af;
    --gray-warm: #796e65;
    --black-1: #353c3e;
    --black-transparant-1: #000000de;
    --grey-2: #00000067;

    --white-1: #fbf6ea;
    --white-2: #fcfaf8;
    --white-3: #f5f5f9;

    --light-blue-1: #dadde9;
}
