@import './renderer-vars.module.scss';
@import '../../../colors.css';

.statusOpen {
    padding: 5px;
    border-radius: 3px;
    background-color: var(--open-status-color-green-1);
}
.statusClose {
    padding: 5px;
    border-radius: 3px;
    background-color: var(--close-status-color-red-1);
}
.MediumPriority {
    padding: 5px;
    border-radius: 3px;
    background-color: var(--medium-priority-color-yellow-1);
}
.labels {
    padding: 5px;
    background-color: var(--label-bg-color-green-2);
    border-radius: 3px;
}
.reports {
    color: var(--primary-dark);
    font-weight: 500;
    text-decoration: none;
}

.actionButton {
    min-width: 25px;
    max-width: 25px;
    margin: 4px 8px 8px 0;
    border: none;
}

.primary {
    color: var(--primary-main);
}

.negative {
    color: var(--error-dark);
}

.negative:hover {
    color: var(--error-main);
}

.primary:hover {
    color: var(--primary-lighter);
}
.boldTitle {
    font-weight: bold;
}

.contact {
    display: flex;
    align-items: center;
    gap: 5px;
}

.icon {
    font-size: large;
    margin-bottom: -4px;
    color: var(--grey-600);
}

.overflowText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
