@import '../../../colors.css';

.headerContainer {
    padding: 1rem;
    border-radius: 36px 36px 0 0;
    border: inherit;
    border-bottom: 1px solid var(--grey-2);
    .capitalize {
        text-transform: capitalize;
    }
}
