@import '../../colors.css';
.searchBox {
    margin: auto auto 16px;
}
.parentDiv {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-bottom: 2px;
    width: 100%;
}

.dragArea {
    border: 2px dashed var(--primary-lighter);
    height: 300px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--grey-150);
    box-shadow: inset 0 0 10px var(--grey-3);
}

.drag-area.active {
    border: 2px solid #fff;
}

.drag-area .icon {
    font-size: 100px;
    color: #fff;
}

.drag-area header {
    font-size: 30px;
    font-weight: 500;
    color: #fff;
}

.drag-area span {
    font-size: 25px;
    font-weight: 500;
    color: #fff;
    margin: 10px 0 15px 0;
}

.UploadButton {
    border: none;
    outline: none;
    background: var(--primary-main);
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
}

.drag-area img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.column {
    float: left;
    width: 50%;
    padding: 10px;
    height: 300px;
}
/* Clear floats after the columns */
.row:after {
    width: 100%;
    content: '';
    display: table;
    clear: both;
}
.FilePreviewContainer {
    padding-top: 10px;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column {
        width: 100%;
    }
}
.PreviewList {
    gap: 5;
    list-style: none;
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    padding: 5px;
    height: 200px;
}
.dropLisItemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--grey-0);
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 2px var(--grey-3);
    margin-bottom: 10px;
}
.dropFileIcon {
    padding: 5px;
    max-width: 50px;
}
.dropFileName {
    padding: 5px;
    width: calc(100% - 80px);
    overflow: hidden;
    white-space: nowrap;
}
.dropFileStatus {
    max-width: 40px;
    display: flex;
    justify-content: center;
}
.editTextField {
    margin: 0 1rem 2rem 1rem;
    width: 540px;
}
